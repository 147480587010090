import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["box", "icon"];

  connect() {
    if (this.boxTarget.classList.contains("hidden")) {
      this.iconTarget.classList.remove("fa-chevron-down");
      this.iconTarget.classList.add("fa-chevron-right");
    } else {
      this.iconTarget.classList.remove("fa-chevron-right");
      this.iconTarget.classList.add("fa-chevron-down");
    }
  }

  toggle(event) {
    event.preventDefault();
    this.boxTarget.classList.toggle("hidden");
    if (this.boxTarget.classList.contains("hidden")) {
      this.iconTarget.classList.remove("fa-chevron-down");
      this.iconTarget.classList.add("fa-chevron-right");
    } else {
      this.iconTarget.classList.remove("fa-chevron-right");
      this.iconTarget.classList.add("fa-chevron-down");
    }
  }
}