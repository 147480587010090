import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modal"]
  static values = {
    seconds: Number,
    warningSeconds: Number,
    refreshUrl: String
  }

  connect() {
    this.sessionTimeoutMs = this.secondsValue * 1000
    this.warningThresholdMs = this.warningSecondsValue * 1000
    this.checkInterval = 1000
    this._boundHandleActivity = this.handleActivity.bind(this)
    this._activityEvents = ["keydown", "click", "scroll"]
    this._activityEvents.forEach((evt) => {
      window.addEventListener(evt, this._boundHandleActivity, { passive: true })
    })

    this._intervalId = setInterval(() => this.checkIdleTime(), this.checkInterval)

    localStorage.setItem("lastActivityAt", Date.now())

    this._storageListener = this.onStorageEvent.bind(this)
    window.addEventListener("storage", this._storageListener)

    this.hideWarning()
  }

  disconnect() {
    this._activityEvents.forEach((evt) => {
      window.removeEventListener(evt, this._boundHandleActivity, { passive: true })
    })

    clearInterval(this._intervalId)
    window.removeEventListener("storage", this._storageListener)
  }

  handleActivity() {
    localStorage.setItem("lastActivityAt", Date.now())
    this.hideWarning()
  }

  onStorageEvent(event) {
    if (event.key === "lastActivityAt") {
      this.hideWarning()
    }

    if (event.key === "forceLogout") {
      this.forceLogoutRedirect()
    }
  }

  checkIdleTime() {
    const now = Date.now()
    const lastActivityAt = parseInt(localStorage.getItem("lastActivityAt") || "0", 10)
    const elapsed = now - lastActivityAt

    const timeLeft = this.sessionTimeoutMs - elapsed
    if (timeLeft <= this.warningThresholdMs && timeLeft > 0) {
      const secondsLeft = Math.ceil(timeLeft / 1000)
      this.updateCountdown(secondsLeft)
      this.showWarning()
    }

    if (elapsed >= this.sessionTimeoutMs) {
      this.expireSession()
    }
  }

  refreshSession(event) {
    event.preventDefault()
    const csrfToken = document.querySelector("meta[name='csrf-token']")?.content
  
    fetch("/keep_alive", {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken,
        "X-Requested-With": "XMLHttpRequest"
      },
      credentials: "include" // ensure cookies are sent
    })
      .then((response) => {
        if (response.ok) {
          localStorage.setItem("lastActivityAt", Date.now())
          this.hideWarning()
        } else {
          console.warn("Session refresh failed; response was not OK.")
        }
      })
      .catch((error) => {
        console.error("Error refreshing session:", error)
      })
  }  

  expireSession() {
    clearInterval(this._intervalId)
    document.querySelector("#deleteSessionButton")?.click()
    localStorage.setItem("forceLogout", Date.now().toString())
  }

  updateCountdown(secondsLeft) {
    const countdownElem = this.modalTarget.querySelector("#countdownNumber")
    if (countdownElem) {
      countdownElem.textContent = secondsLeft
    }
  }

  showWarning() {
    this.modalTarget.classList.remove("hidden")
  }

  hideWarning() {
    this.modalTarget.classList.add("hidden")
  }

  signOut(event) {
    event.preventDefault()

    const signOutLink = document.querySelector("#deleteSessionButton")
    if (signOutLink) {
      signOutLink.click()
    }

    localStorage.setItem("forceLogout", Date.now().toString())
  }

  forceLogoutRedirect() {
    setTimeout(() => {
      window.location.href = "/"
    }, 1000)
  }
}
