import { Controller } from "@hotwired/stimulus"
import * as ClipboardFunctions from "../lib/ClipboardFunctions.js";
import flash from "../lib/flashes.js";

export default class extends Controller {
  static values = { url: String, text: String, isOtp: Boolean }
  static targets = ["icon", "button"]

  connect() {
    this.prefetchedData = null;
    const url = this.urlValue;
    const isOtp = this.isOtpValue;

    if (!this.textValue) {
      fetch(url, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
        .then(response => response.json())
        .then(data => {
          this.prefetchedData = isOtp ? data["otp"] : data["password"];
        })
        .catch(error => console.error('Error pre-fetching data:', error));
    }
  }

  copy(event) {
    event.preventDefault();

    const text = this.textValue || this.prefetchedData;

    if (text) {
      this.copyToClipboard(text);
    } else {
      console.error('No data available to copy');
    }
  }

  copyToClipboard(content, successMessage = "Copied to clipboard") {
    navigator.clipboard.writeText(content).then(() => {
      flash(successMessage, "success");
      this.handleUX();
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  }

  handleUX () {

    if (!this.hasIconTarget && !this.hasButtonTarget) {
      return
    }

    if (this.hasButtonTarget) {
      var oldHTML = this.buttonTarget.innerHTML
      this.buttonTarget.innerHTML = "Copied!"
      setTimeout(() => {
        this.buttonTarget.innerHTML = oldHTML
      }, 3000)
    } else {
      // this.iconTarget.style = "display: none"
      var oldHTML = this.iconTarget.innerHTML
      this.iconTarget.innerHTML = "<i class='fas fa-check success'></i>"

      setTimeout(() => {
        this.iconTarget.innerHTML = oldHTML
      }, 3000)
    }

  }
}
