import { Controller } from "@hotwired/stimulus";
import crs from "../lib/country-region-selector"; // Adjust the path if necessary

export default class extends Controller {
  static targets = ["country", "region"];

  connect() {
    crs.init();
  }
}
