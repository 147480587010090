import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
  }

  submit(event) {
    event.target.form.submit()
  }
}
