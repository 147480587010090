import { Controller } from '@hotwired/stimulus';
import { useTransition } from 'stimulus-use';

export default class extends Controller {
  static targets = ['menu'];

  connect() {
    useTransition(this, {
      element: this.menuTarget,
      enterActive: 'enter-active',
    });
  }

  toggle(event) {
    this.toggleTransition();
  }

  hide(event) {
    const node = event.target;
    if (!this.element.contains(node) && !this.menuTarget.classList.contains('hidden')) {
      this.leave();
    }
  }
}