import { Controller } from "@hotwired/stimulus"
import flash from "../lib/flashes.js"
import axios from "axios"

export default class extends Controller {
  static targets = [ "result" ]
  static values = {
    url: String,
    isIntegration: {
      type: Boolean,
      default: false
    }
  }

  connect() {
    this.checkForStoredResult();
  }

  test() {
    const that = this
    // Custom headers
    const headers = {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    };

    axios.post(that.urlValue, null, {headers: headers}).then(function (response) {
      localStorage.setItem('connection_test_result', 'success');
      flash("Connection test successful","success")
      window.location.reload()
    }).catch(error => {
      if (error.response && error.response.data && error.response.data.message) {
        localStorage.setItem('connection_test_result', error.response.data.message);
        flash(error.response.data.message,"error")
      } else {
        localStorage.setItem('connection_test_result', 'Unable to connect');
        flash("Unable to connect","error")
      }
      window.location.reload()
    })
  }

  checkForStoredResult() {
    const result = localStorage.getItem('connection_test_result');
    if (result) {
      flash(result === 'success' ? "Connection test successful" : result, result === 'success' ? "success" : "error");
      localStorage.removeItem('connection_test_result');
    }
  }
}
